import React, { useEffect, useState } from 'react';
import RiskMeter from './RiskMeter';
// import board_problem_1 from '../../assets/board_problem-1.svg';
import noun_justice_law from '../../assets/noun-justice-law-4345672.svg'
import logo_i from '../../assets/logo_i.svg';
import noun_dashboard from '../../assets/noun-dashboard.svg';
import menu_page_precedente from '../../assets/menu_page_precedente.svg';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

function SideBar({
    nbObligationsApplicables,
    nbObligationspotentiels,
    raisonSociale,
    isOpen,
    isMobile,

    setIsMobile,
    setIsOpen,
    toggleSidebar

}) {
    const navigate = useNavigate();

    const goHome = () => {
        localStorage.removeItem('questionnaireState');
        localStorage.removeItem('result');
        localStorage.removeItem('raisonSociale');
        navigate('/')
    }

    const goPageSiret = () => {
        navigate('/questionnaire')
    }

    const goPageSiretAndResetQuestionnaire = () => {
        localStorage.removeItem('questionnaireState');
        localStorage.removeItem('result');
        localStorage.removeItem('raisonSociale');
        navigate('/questionnaire')
    }

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth < 768;
            setIsMobile(isMobileView);
            setIsOpen(!isMobileView);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function calculateRiskIndicator(n_content_ko, n_content_maybe) {
        // Total des contenus évalués
        const total = n_content_ko + n_content_maybe;

        // Vérifier si le total est non nul pour éviter la division par zéro
        if (total === 0) {
            return 0; // Retourner 0 si aucun contenu n'est présent
        }

        // Calculer le risque en considérant 'ko' comme 100% de risque, 'maybe' comme 50%, et 'ok' comme 0%
        const riskScore = (n_content_ko * 1.0 + n_content_maybe * 0.5) / total;

        // Convertir le score de risque en pourcentage
        const riskPercentage = riskScore * 100;

        return riskPercentage;
    }

    return (
        <>
            <div className={`
                fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}
                md:relative md:translate-x-0 transition duration-200 ease-in-out
               bg-[#006acc] shadow-lg z-20 overflow-auto
            `}>
                <div className="text-white p-8 flex flex-col h-screen max-w-[400px]">
                    {/* <nav className="flex items-center justify-between mb-8"> */}
                    <div className="flex  items-center justify-between space-x-4  mb-8">
                        <button className="bg-white p-2 rounded-full" onClick={goHome} >
                            <img src={logo_i} alt="Info" className="w-8 h-8" />
                        </button>
                        {/* {!isMobile &&
                                <button className="bg-white p-2 rounded-full" onClick={goPageSiret}>
                                    <img src={menu_page_precedente} alt="Page précédente" className="w-8 h-8" />
                                </button>
                            } */}
                        <div className="p-2">
                            <h1 className="text-2xl md:text-3xl text-white font-semibold"> Etat des lieux</h1>
                        </div>

                        {/* <button className="bg-white pl-2 pr-4 py-1 rounded-full font-bold flex items-center">
                                <img src={noun_dashboard} alt="Dashboard" className="w-8 h-8 text-[#151b67]" />
                                <span className='text-[#151b67] text-sm'> <p className='text-nowrap'>Aller à L'ESPACE </p> <p>DE TRAVAIL</p></span>
                                <img src={menu_page_precedente} alt="Page précédente" className="ml-2 w-6 h-6 rotate-180" />

                            </button> */}
                        {isMobile &&
                            <button
                                className="p-2 bg-white rounded-md shadow-lg"
                                onClick={toggleSidebar}
                                aria-label={isOpen && isMobile ? "Fermer le menu" : "Ouvrir le menu"}
                            >
                                {isOpen && isMobile ? <FaTimes size={30} color='#006acc' /> : <FaBars size={30} color='#006acc' />}
                            </button>
                        }
                    </div>
                    {/* </nav> */}
                    {/* <h1 className="text-2xl font-bold mb-8"> {raisonSociale || '[ ND ]'}</h1> */}
                    <div className="bg-[#ffffff33] p-4 rounded-lg mb-4 space-y-5">
                        <div className="menu__step">
                            <p className="menu__step--text" data-translate="">Obligations applicables</p>
                            <p className="menu__step--number" data-field="nbApplicables">{nbObligationsApplicables}</p>
                        </div>
                        <div className="menu__step">
                            <p className="menu__step--text" data-translate="">Obligations potentiels</p>
                            <p className="menu__step__dashed--number" data-field="nbApplicables">{nbObligationspotentiels.Total}</p>
                        </div>
                        {/* <div className="menu__step--footer">
                            <div className="menu__step">
                                <p className="menu__step--text" data-translate="">Pénalités encourues</p>
                                <div className="menu__step__problem pr-7">
                                    <img src={noun_justice_law} alt="" className="menu__step__problem--img" />
                                    <div className="menu__step__problem--texts">
                                        <p className="menu__step__problem--from" data-translate="">Jusqu'à</p>
                                        <p className="menu__step__problem--price" data-field="penalites">524&nbsp;k€</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <p className='text-lg font-bold pt-2'>Risque de non-conformité</p>
                        <RiskMeter riskLevel={calculateRiskIndicator(nbObligationsApplicables, nbObligationspotentiels.Total)} />
                    </div>
                    <div onClick={goPageSiret} className="bg-[#006acc] border flex justify-center border-white p-4 rounded-lg mb-4 hover:bg-[#ffffff33] cursor-pointer">
                        Modifier les réponses
                    </div>
                    <div onClick={goPageSiretAndResetQuestionnaire} className="bg-[#006acc] border flex justify-center border-white p-4 rounded-lg mb-4 hover:bg-[#ffffff33] cursor-pointer">
                        Réinitialiser le questionnaire
                    </div>
                </div>
            </div>
            {isOpen && (
                <button
                    className="fixed inset-0 w-full h-full bg-black bg-opacity-50 z-10 md:hidden cursor-default"
                    onClick={toggleSidebar}
                    aria-label="Fermer le menu latéral"
                    style={{ appearance: 'none', border: 'none', outline: 'none' }}
                />
            )}
        </>
    );
}

export default SideBar;
