import React from 'react';
import card_fiscal from '../../assets/card_fiscal.svg';
import card_gestion from '../../assets/card_gestion.svg';
import card_environnemental from '../../assets/card_environnemental.svg'
import card_social from '../../assets/card_social.svg'
import noun_autorized from '../../assets/noun-autorized-4504732.svg'
import noun_shareholder from '../../assets/noun-shareholder-4244455.svg'
import medicine_health from '../../assets/medical-medicine-health-23-svgrepo-com.svg'

const ThematicBlock = ({ index, title, details, obligations }) => {
    const getColor = (title) => {
        switch (title) {
            case 'Fiscalite - Impots - Taxes':
                return '#ffd23e';
            case 'Comptabilite - Gestion - Finances':
                return '#eb356c';
            case 'Environnement - Developpement durable':
                return '#40c9a0';
            case 'Ressources humaines':
                return '#75aaff';
            case 'Compliance':
                return '#da92f0';
            case 'Gouvernance - Engagements':
                return '#babbc0'
            case "Santé de l'Homme et de son environnement de travail":
                return '#161C67'

            case 'Prestation de service':
                return '#ffd23e';
            case 'Service numérique':
                return '#eb356c';
            case 'Produit':
                return '#40c9a0';
            case 'Formulaire Cerfa':
                return '#75aaff';

            case 'Modèle de document':
                return '#babbc0'
            case "Téléservice":
                return '#da92f0'
            default:
                return '#161C67';
        }
    }

    function getIcon(title) {
        switch (title) {
            case 'Fiscalite - Impots - Taxes':
                return card_fiscal;
            case 'Comptabilite - Gestion - Finances':
                return card_gestion;
            case 'Environnement - Developpement durable':
                return card_environnemental;
            case 'Ressources humaines':
                return card_social;
            case 'Compliance':
                return noun_autorized;
            case 'Gouvernance - Engagements':
                return noun_shareholder;
            case "Santé de l'Homme et de son environnement de travail":
                return medicine_health;
            default:
                return null;
        }
    }

    return (
        <div key={index} className="w-full">
            <div className="card-category card-category-fis">
                <div className="border-left" style={{ 'background-color': getColor(title) }}>
                    <img src={getIcon(title)} alt="" className="border-left--img" />
                </div>
                <div className="card-category--content hover:bg-[#F7F7F7] ">
                    <div className="card-category__header rounded-md bg-white">
                        <span className="text-sm font-bold leading-7 pl-2 uppercase text-[#1e4690] break-words">
                            {title} {/* Affiche le type */}
                        </span>
                        <div className="card-category__number">
                            <p className="card-category__number--value">{obligations}</p>
                        </div>
                    </div>
                    <p className="card-category__detail mb-2">Sous-thématique(s)</p>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-1">
                        {details.map((detail, index) => (
                            <div key={index} className="bg-[#F7F7F7] rounded-[5px] h-9 basis-[calc(50%_-_15px)] hover:bg-[#fff] flex justify-between items-center p-2 sm:col-span-1 col-span-1">
                                <p className="card-category__item--text flex-grow">{detail.name}</p>
                                <div className="card-category__item__number">
                                    <span className="card-category__item__number--value">{detail.count}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ThematicBlock;
