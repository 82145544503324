import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';
import ContinueButton from './ContinueButton';


const DetentionSiteInternet = ({ nbStep, isComanyCommercial, nbCandtionMicroValide, isSCI, moveToStep, goBack, raisonSociale, isMicroEntreprise,
    detentionSiteInternetQ, setDetentionSiteInternetQ,

    SIRET,
    codeNaf,
    NAFsection,
    catJuridiqueNiv1,
    catJuridiqueNiv2,
    catJuridiqueNiv3,
    chiffredAffairesTotalNetX,
    nbMoyenSalaries,
    regimeTVANormalSimplifieFranQ,
    optionRegimeMicroEntrepriseQ,
    optionImpotSocieteISQ,

    periodiciteDeclarationCA,
    revenuFiscalReference,
    versementLiberatoireIR,

    SiegeLocauxEnCommunX,
    dateDomiciliationLocauxCommun,

    cocontractantPrestationSup5k,
    versHonorairesComSup1200,
    emissionFactureSecteurPublicQ,
    utilisationTraceurCookies,
}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const decomposeCodeNaf = (code) => {
        const parts = code.match(/^(\d{2})\.(\d{2})([A-Z])$/);
        if (!parts) return null;

        return {
            NAFdivision: parts[1],
            NAFgroupe: `${parts[1]}.${parts[2].charAt(0)}`,
            NAFclasse: `${parts[1]}.${parts[2]}`,
            NAFsousClasse: code
        };
    };



    const handleConfirm = async () => {

        if (detentionSiteInternetQ) {
            moveToStep('UsedTraceurCookies');
        } else {
            if (isMicroEntreprise) {
                setIsLoading(true);
                const postData = [{
                    scope: {
                        types: ["Obligation", "droit"],
                        categories: [
                            "Compliance",
                            "Comptabilite - Gestion - Finances",
                            "Environnement - Developpement durable",
                            "Fiscalite - Impots - Taxes",
                            "Gouvernance - Engagements",
                            "Ressources humaines"
                        ],
                        laws: ["France", "Europe"],
                        modules_only: false,
                        module_id: null
                    },
                    variables: {
                        SIRET: SIRET,
                        catJuridiqueNiv1: catJuridiqueNiv1,
                        catJuridiqueNiv2: catJuridiqueNiv2,
                        catJuridiqueNiv3: catJuridiqueNiv3,
                        NAFsection: NAFsection,
                        ...decomposeCodeNaf(codeNaf),

                        'resultat[2023].chiffredAffairesTotalNet': Math.ceil(chiffredAffairesTotalNetX), //1
                        'nbMoyenSalaries[2023]': Math.ceil(nbMoyenSalaries), // 2
                        regimeTVANormalSimplifieFranQ: regimeTVANormalSimplifieFranQ, //3
                        optionRegimeMicroEntrepriseQ: optionRegimeMicroEntrepriseQ,
                        optionImpotSocieteISQ: optionImpotSocieteISQ,
                        periodiciteDeclarationCA: periodiciteDeclarationCA,
                        revenuFiscalReference: revenuFiscalReference,
                        versementLiberatoireIR: versementLiberatoireIR,
                        SiegeLocauxEnCommunX: SiegeLocauxEnCommunX,
                        dateDomiciliationLocauxCommun: dateDomiciliationLocauxCommun,
                        versHonorairesComSup1200: versHonorairesComSup1200,
                        emissionFactureSecteurPublicQ: emissionFactureSecteurPublicQ,
                        cocontractantPrestationSup5k: cocontractantPrestationSup5k, //23
                        detentionSiteInternetQ: detentionSiteInternetQ, //25
                        utilisationTraceurCookies: utilisationTraceurCookies, //26
                        // operateurPlateforme: operateurPlateforme // 27

                    }
                }];
                console.log(postData);

                try {
                    const { apiUrl } = getEnvConfig();
                    console.log("apiUrl: ", apiUrl + 'run_simulator')
                    const response = await fetch(apiUrl + 'run_simulator', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        body: JSON.stringify(postData)
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const result = await response.json();
                    console.log(result)
                    if (result?.erros) {
                        alert("Une erreur s'est produite lors du calcul des obligations.")
                        navigate('/questionnaire');
                    }
                    // navigate('/workspace', { state: { result, raisonSociale } });
                    localStorage.setItem('result', JSON.stringify(result));
                    localStorage.setItem('raisonSociale', raisonSociale);
                    navigate('/workspace');

                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                    alert("Une erreur s'est produite lors du calcul des obligations.")
                    navigate('/questionnaire');
                } finally {
                    setIsLoading(false);
                }
            } else if (isComanyCommercial) {
                moveToStep('prodDetDechets5flux')
            } else {
                moveToStep('dateClosed');
            }
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const Loader = () => (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            {isLoading && <Loader />}
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} possède un site internet (site vitrine, plateforme, site de e-commerce, etc.) ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} has a website (showcase site, platform, e-commerce site, etc.)?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${detentionSiteInternetQ === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setDetentionSiteInternetQ(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${detentionSiteInternetQ === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setDetentionSiteInternetQ(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(detentionSiteInternetQ !== null)}
                    onConfirm={handleConfirm}
                    isLastQst={isMicroEntreprise && !detentionSiteInternetQ}
                />
            </div>
        </div>
    );
};

export default DetentionSiteInternet;
