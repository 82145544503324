import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import france_logo from '../../assets/french.png';
import uk_logo from '../../assets/uk_logo.png';
import ThematicBlock from './ThematicBlock';
import ModalPayments from './ModalPayments';
import { FaBars, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import { useAppContext } from '../../context/AppContext';
import UserDropdownMenu from '../UserDropdownMenu';
import { getEnvConfig } from '../../utils/config';

function MainRecapWorkspace({ nbObligations, thematiques, result,
    isMobile,
    isOpen,
    raisonSociale,

    currentUser,
    userData,
    setIsMobile,
    setIsOpen,
    toggleSidebar }) {

    const { t, i18n } = useTranslation();
    const [modalPayments, setModalPayments] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const currentLanguage = i18n.language;
    const otherLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
    const flagToShow = otherLanguage === 'fr' ? france_logo : uk_logo;
    const altText = otherLanguage === 'fr' ? 'FR' : 'EN';
    // const { currentUser, userData } = useAppContext();

    console.log("userData MainRecapWorkspace :")

    const [isLoading, setIsLoading] = useState(false);

    const Loader = () => (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );

    const handleDownload = async () => {
        if (currentUser && userData && userData.remaining_reports > 0) {
            const user = currentUser;
            const idToken = await user.getIdToken();

            setIsLoading(true);
            try {
                const { apiUrl } = getEnvConfig();
                const response = await fetch(apiUrl + "generate-report", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${idToken}`,
                    },
                    body: JSON.stringify({ result }),
                });
                if (response.ok) {
                    // Récupérer le blob du fichier PDF
                    const blob = await response.blob();
                    // Créer un lien de téléchargement
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rapport_conformite.pdf');
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                } else {
                    const errorData = await response.json();
                    alert(errorData.detail);
                }
            } catch (error) {
                console.error('Erreur lors du téléchargement:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            alert('Vous n\'avez plus de rapports restants. Veuillez souscrire à un pack.');
        }
    };

    const DownloadReportButton = ({ nbObligations, titreType, currentUser, userData, titreFooter, handleDownload, setModalPayments }) => {
        return (
            <>
                <div className="flex justify-between mb-2 border-b-[3px] border-[#1e4690] py-2">
                    <h3 className="text-lg text-[#1e4690] self-center font-bold">{nbObligations} {titreType}</h3>
                    {currentUser && userData && userData.remaining_reports > 0 ? (
                        <button className="group bg-blue-800 hover:bg-[#006ACC] text-white p-2 rounded px-4 transition duration-300 ease-in-out"
                            onClick={handleDownload}
                        >
                            <div className="flex items-center">
                                Télécharger le RAPPORT DETAILLE
                            </div>
                        </button>
                    ) : (
                        <button className="group bg-blue-800 hover:bg-[#006ACC] text-white p-2 rounded px-4 transition duration-300 ease-in-out"
                            onClick={() => setModalPayments(true)}
                        >
                            <div className="flex items-center">
                                Accéder au RAPPORT DETAILLE <FaLongArrowAltRight className="ml-4 transition-transform duration-300 ease-in-out group-hover:translate-x-3" />
                            </div>
                        </button>
                    )}
                </div>
                <h1 className="text-lg text-[#1e4690] mb-3">{titreFooter}</h1>
            </>
        );
    };


    return (
        <main className="flex-1 overflow-y-auto">
            {isLoading && <Loader />}
            <div className="max-w-7xl mx-auto py-6 sm:py-1 px-4 sm:px-6 lg:px-8">
                <div className="md:py-10 sm:py-0 w-full board">
                    <nav className="flex justify-between items-center mb-4">
                        <h1 className="text-3xl text-[#1e4690] font-semibold mb-4">{raisonSociale}</h1>
                        <div className="flex items-center space-x-4">
                            {/* <button onClick={() => changeLanguage(otherLanguage)} className="items-center hidden md:flex">
                                <img src={flagToShow} alt={altText} className="h-8 mr-2" />
                            </button> */}
                            {currentUser &&
                                <UserDropdownMenu
                                    handleContactClick={() => setModalPayments(true)}
                                />
                            }
                            {!isOpen &&
                                <button
                                    className="z-30 p-2 bg-white rounded-md shadow-lg"
                                    onClick={toggleSidebar}
                                    aria-label={isOpen && isMobile ? "Fermer le menu" : "Ouvrir le menu"}
                                >
                                    {isOpen && isMobile ? <FaTimes size={30} color='#006acc' /> : <FaBars size={30} color='#006acc' />}
                                </button>
                            }
                        </div>
                    </nav>
                    <DownloadReportButton
                        nbObligations={thematiques[0].reduce((total, thematique) => {
                            return total + thematique.details.reduce((acc, detail) => acc + detail.count, 0);
                        }, 0)}
                        titreType="OBLIGATIONS LEGALES"
                        currentUser={currentUser}
                        userData={userData}
                        handleDownload={handleDownload}
                        setModalPayments={setModalPayments}
                        titreFooter={"Détails par thématiques "}
                    />


                    <div className="view view-grid d-block">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5" id="details">
                            {thematiques[0].map((thematique, index) => {
                                // Calculer la somme des counts pour la thematique courante
                                const obligations = thematique.details.reduce((acc, detail) => acc + detail.count, 0);

                                return (
                                    <ThematicBlock
                                        key={index} // Assure-toi d'ajouter une clé unique pour chaque enfant dans la liste
                                        index={index}
                                        title={thematique.title}
                                        obligations={obligations} // Passer la somme calculée
                                        details={thematique.details}
                                    />
                                );
                            })}

                        </div>
                    </div>

                    <DownloadReportButton
                        nbObligations={thematiques[1].reduce((total, thematique) => {
                            return total + thematique.details.reduce((acc, detail) => acc + detail.count, 0);
                        }, 0)}
                        titreType="MESURES VOLONTAIRES"
                        currentUser={currentUser}
                        userData={userData}
                        handleDownload={handleDownload}
                        setModalPayments={setModalPayments}
                        titreFooter={"Détails par thématiques"}
                    />

                    <div className="view view-grid d-block">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5" id="details">
                            {thematiques[1].map((thematique, index) => {
                                // Calculer la somme des counts pour la thematique courante
                                const obligations = thematique.details.reduce((acc, detail) => acc + detail.count, 0);

                                return (
                                    <ThematicBlock
                                        key={index} // Assure-toi d'ajouter une clé unique pour chaque enfant dans la liste
                                        index={index}
                                        title={thematique.title}
                                        obligations={obligations} // Passer la somme calculée
                                        details={thematique.details}
                                    />
                                );
                            })}

                        </div>
                    </div>

                    <DownloadReportButton
                        nbObligations={thematiques[2].reduce((total, solutions) => {
                            return total + solutions.titles.reduce((acc, solution) => acc + solution.count, 0);
                        }, 0)}
                        titreType="SOLUTIONS DE MISE EN CONFORMITE"
                        currentUser={currentUser}
                        userData={userData}
                        handleDownload={handleDownload}
                        setModalPayments={setModalPayments}
                        titreFooter={"Détails par type"}
                    />

                    <div className="view view-grid d-block">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5" id="details">
                            {thematiques[2].map((solution, index) => {
                                // Calculer la somme des counts pour la thematique courante
                                // const obligations = thematique.details.reduce((acc, detail) => acc + detail.count, 0);

                                return (
                                    <ThematicBlock
                                        key={index} // Assure-toi d'ajouter une clé unique pour chaque enfant dans la liste
                                        index={index}
                                        title={solution.type}
                                        obligations={solution.count} // Passer la somme calculée
                                        details={[]}
                                    />
                                );
                            })}

                        </div>
                    </div>
                    <footer className="flex  justify-end mt-auto">
                        {currentUser && userData && !userData.remaining_reports > 0 &&
                            <button className="group mt-auto bg-blue-800 hover:bg-[#006ACC] text-white p-2 rounded px-4 transition duration-300 ease-in-out"
                                onClick={() => setModalPayments(true)}
                            >
                                <div className="flex items-center">
                                    Accéder au RAPPORT DETAILLE <FaLongArrowAltRight className="ml-4 transition-transform duration-300 ease-in-out group-hover:translate-x-3" />
                                </div>
                            </button>
                        }

                    </footer>

                    <ModalPayments isOpen={modalPayments} onClose={() => setModalPayments(false)} result={result} />

                </div>
            </div>
        </main >
    )
}


export default MainRecapWorkspace;
