import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";

const QualitePersonnes = ({ legalContent }) => {
    return (
        <div className="flex flex-col bg-white pt-[72px]">
            <NavigationBar />
            <div className="relative flex-grow flex flex-col text-start min-h-96">
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-3xl text-[#1244a0] font-bold mb-6">Qualité des personnes autorisées à déposer une offre de produits ou services, descriptif du service de mise en relation</h1>
                    <div className="col-md-12 text-[#919191] text-lg" id="texte" dangerouslySetInnerHTML={{ __html: legalContent }} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default QualitePersonnes;